.Toastify__toast--error {
  border: 2px solid #ea5f5f;
  border-radius: 4px !important;
  background: #f9d8d8 !important;
}

.Toastify__toast--success {
  border: 2px solid #0d6d0d !important;
  border-radius: 4px !important;
  background: #ebf8e0 !important;
}

.Toastify__toast--warning {
  border: 2px solid #99600b !important;
  border-radius: 4px !important;
  background: #fdf1df !important;
}

.Toastify__toast--info {
  border: 2px solid #258bc6 !important;
  border-radius: 4px !important;
  background: #c8e1f1 !important;
}

.Toastify__toast-body {
  color: #000000;
  font-size: 13px;
}

.Toastify__toast {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
